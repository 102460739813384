import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const ContactIntro = loadable(() => import("../components/ContactIntro/ContactIntro"));
const ContactDetail = loadable(() => import("../components/ContactDetail/ContactDetail"));
const JobForm = loadable(() => import("../components/ContactForm/JobForm"));

const ApplyJobForm = (props) => {
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule />

            <div className="contact-page-wrapper section-p">
                <Container>
                    <Row>
                        <Col xl={4}>
                            <ContactIntro 
                                tag="job-apply"
                            />

                            <ContactDetail />
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={7}>
                            <JobForm />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export default ApplyJobForm